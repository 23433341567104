import "./App.css";
import Routers from "./routers/Routers";
import "./index.css";
import PreLoader from './components/preloader/PreLoader'
import firstimage from './assets/Oryze-Menu-Maintenance-AA.png'
import secondimage from './assets/Oryze-Menu-Maintenance-BB.png'

function App() {
  return (
    <>
     <PreLoader /> 
      <div className="App bg-[#2F2424] w-screen ">
       <Routers />
        {/**
      <img src={firstimage} className="fixed top-0 left-0 bottom-0 right-0 w-screen h-screen"/>
      <img src={secondimage} className="fixed  left-0 bottom-0 right-0 w-screen h-32"/>
      */} 
      </div>
    </>
  );
}

export default App;

import React, { useEffect } from "react";
import "./preloader.css";
import { preLoaderAnim, Arabicprev } from "../../animations";
import O from "../../assets/o.png";
import R from "../../assets/r.png";
import Y from "../../assets/y.png";
import Z from "../../assets/z.png";
import E from "../../assets/e.png";
import Oa from "../../assets/ا_1.png";
import Ra from "../../assets/و.png";
import Ya from "../../assets/ر.png";
import Zz from "../../assets/ي ز.png";
import Ea from "../../assets/ي.png";
import LEwan from '../../assets/Lewanـtransparentـlogo.png'
function PreLoader() {
  useEffect(() => {
    preLoaderAnim();
  }, []);
  return (
    <div className="preloader h-screen  flex-col relative ">
      <div className=" ">
     {/**    <span>
          <img loading="lazy" src={O} alt="" className="w-36 e" />
        </span>
        <span>
          <img loading="lazy" src={R} alt="" className="w-20 e" />
        </span>
        <span>
          <img loading="lazy" src={Y} alt="" className="w-20 e" />
        </span>
        <span>
          <img loading="lazy" src={Z} alt="" className="w-24 e" />
        </span>
  
        <span>
          <img loading="lazy" src={E} alt="" className=" w-32 e" />
        </span>
        */}
        <span>
        <img loading="lazy" src={LEwan} alt="" className=" w-32 e" />
      </span>
      </div>
  {/**  <div
        style={{
          transform: "scale(0.85)",
          justifyContent: "right",
          margin: "0",
        }}
        className="texts-container  ml-[5%]"
      >
        <span>
          <img loading="lazy" src={Ea} a alt="" className="w-12 h-10 a " />
        </span>
        <span>
          <img loading="lazy" src={Zz} alt="" className="w-5 h-10 a mx-0.5" />
        </span>
        <span>
          <img loading="lazy" src={Ya} alt="" className="w-3 h-10 a " />
        </span>
        <span>
          <img loading="lazy" src={Ra} alt="" className="w-7 h-10 a mx-0.5" />
        </span>
        <span>
          <img loading="lazy" src={Oa} a alt="" className="w-3 h-14 -mt-3 a" />
        </span>
      </div>
       */}
      {/**  <p className="py-2 font-medium text-white/75  ">Asian Fusion Food</p>*/}
    </div>
  );
}

export default PreLoader;
